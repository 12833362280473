import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import news7pic1 from '../assets/images/race.jpg'
import ios from '../assets/images/app-store.svg'
import google from '../assets/images/google-play.svg'
const News7 = props => (
  <Layout>
    <Helmet>
      <title>三健客 3musclers - 【第一屆三健客人氣爭霸戰——正式開戰👑】</title>
      <meta name="description" content="第一屆三健客人氣爭霸戰——正式開戰" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>【第一屆三健客人氣爭霸戰👑】</h1>
          </header>
          <div className="box alt">
            <div className="grid-wrapper">
              <div className="col-4">
                <span className="image main">
                  <img src={news7pic1} alt="" />
                </span>
              </div>
            </div>
          </div>
          <h3>​🤜🏼活動日期：1/15（五）～2/07（日）</h3>
          <h3>🤜🏼活動內容：共有7位選手參賽，爭奪第一屆三健客人氣王寶座👑</h3>
          <h3>
            ​🤜🏼活動辦法：活動期間內，至三健客IG為你支持的選手點愛心即算完成投票❤️{' '}
          </h3>
          <p>在活動截止之後，讚數最高的即是當屆的人氣王！</p>
          <p>
            🏋🏻‍♂️我要預測人氣王：如果要參與預測，請在你支持的選手貼文底下留言指定內容，
            若最後預測成功，則會從底下留言預測的人中，抽出一位贈送指定獎品！
            （⚠️注意！每個選手的賠率都不太一樣喔😏）
          </p>
          <p>
            話不多說！晚上9:00將會在IG上公布選手資訊，
            準備好為你支持的選手投票了嗎🔥🔥🔥
          </p>
			 <h3>這裡預備備</h3>
			 <ul className="icons">
            <li>
              <a
                href="https://www.instagram.com/3musclers/?hl=zh-tw"
                className="icon alt fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          <iframe
            src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2F3muscler%2F&width=450&layout=standard&action=like&size=small&share=true&height=35&appId=3212226998840909"
            width="450"
            height="35"
            scrolling="no"
            frameborder="0"
            allowTransparency="true"
            allow="encrypted-media"
          ></iframe>
        </div>
      </section>
    </div>
  </Layout>
)

export default News7
